import React, { Component } from "react";
import {
  Row,
  Col,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Button,
  FormGroup,
  Input,
  Container,
  Nav,
  NavItem,
} from "reactstrap";
import Carouselsecond from "../../Carouselsecond";
import logo from "../../assets/img/Group 6.png";
import { Link, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
export default class Header extends Component {
  constructor() {
    super();
    this.state = { showNav: false };
  }

  handleNav = () => {
    // console.log("clicked");
    this.setState({ showNav: !this.state.showNav });
  };
  render() {
    const pathName = window?.location?.pathname;
    // const mb0 = { margin: "0px !important" };
    // const pdrl25 = { padding: "0px 25px" };
    return (
      // <div style={pdrl25} className="headerSec">
      //   <Navbar expand="md">
      //     <Container fluid>
      //       <Row className="mainHeaderDiv">
      //         <Col sm={2} md={2} className="leftColMainHeader">
      //           <NavbarBrand href="/">
      //             <img src={logo} className="App-logo" alt="logo" />
      //           </NavbarBrand>
      //         </Col>
      //         <Col sm={10} md={10} className="rightColMainHeader">
      //           <Nav className="d-flex align-items-end justify-content-end w-100">
      //               <NavItem>
      //                   <NavLink
      //                   to={"/home"}
      //                   className="nav-link"
      //                   activeClassName="active"
      //               >
      //                   Home
      //               </NavLink>
      //               </NavItem>
      //               <NavItem>
      //               <NavLink to={"/products"} className="nav-link" activeClassName="active">
      //                   Products
      //               </NavLink>
      //               </NavItem>
      //           </Nav>
      //            <Col sm={10} md={10} className="leftInnerColDiv">
      //               <form>
      //                 <FormGroup style={mb0}>
      //                   <Input
      //                     name="search"
      //                     placeholder="search placeholder"
      //                     type="search"
      //                     style={mb0}
      //                   />
      //                 </FormGroup>
      //               </form>
      //             </Col>
      //             <Col
      //               sm={2}
      //               md={2}
      //               className="d-flex justify-content-center rightInnerColDiv"
      //             >
      //               <NavbarText>
      //                <Button className = "Headerbutton">
      //                                           Log Out
      //                                   </Button>
      //               </NavbarText>
      //             </Col>
      //         </Col>
      //       </Row>
      //     </Container>
      //   </Navbar>
      // </div>

      <div>
        <Navbar expand="md">
          <Container fluid>
            <Row>
              <Col>
                {/* <MarketPriceCarousel/> */}
                <Carouselsecond />
              </Col>
            </Row>
          </Container>
        </Navbar>
        <header id="header" className="d-flex align-items-center">
          <div className="container p-0 headerMenu">
            <h1 className="logo">
              <NavLink to="/home">
                <img src={logo} className="img-fluid" alt="" />
              </NavLink>
            </h1>

            <div>
              <nav id="navbar" className="navbar">
                <ul>
                  <li>
                    <NavLink
                      className="nav-link scrollto"
                      to="/home"
                      activeClassName="active"
                    >
                      Home
                    </NavLink>
                  </li>

                  <li>
                    <span
                      className="toolTip-Menu"
                      style={{
                        position: "absolute",
                        top: -6,
                        fontSize: 11,
                        fontWeight: 600,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 5,
                        paddingRight: 5,
                        color: "#fff",
                        borderRadius: 7,
                        background: "#980033",
                      }}
                    >
                      New
                    </span>
                    <NavLink
                      className="nav-link scrollto "
                      to="/products"
                      activeClassName="active"
                    >
                      Order Now
                    </NavLink>
                  </li>

                  {pathName === "/home" && (
                    <>
                      <li>
                        <a className="nav-link scrollto" href="#awords">
                          Awards & Media
                        </a>
                      </li>
                      <li>
                        <a className="nav-link scrollto" href="#partnerships">
                          Partnerships
                        </a>
                      </li>
                      <li>
                        <a className="nav-link scrollto" href="#gallery">
                          Gallery
                        </a>
                      </li>
                    </>
                  )}
                </ul>

                {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
              </nav>
            </div>

            <div className={this.state.showNav ? "active" : "mobile-menu"}>
              {this.state.showNav && (
                <nav id="navbar" className="mobile-menu">
                  <ul className="mobile-nav">
                    <li>
                      <NavLink
                        className="nav-link scrollto"
                        to="/home"
                        activeClassName="active"
                      >
                        Home
                      </NavLink>
                    </li>

                    <li>
                      <span
                        className="toolTip-Menu"
                        style={{
                          position: "absolute",
                          top: -6,
                          fontSize: 11,
                          fontWeight: 600,
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 5,
                          paddingRight: 5,
                          color: "#fff",
                          borderRadius: 7,
                          background: "#980033",
                        }}
                      >
                        New
                      </span>
                      <NavLink
                        className="nav-link scrollto "
                        to="/products"
                        activeClassName="active"
                      >
                        Order Now
                      </NavLink>
                    </li>

                    {pathName === "/home" && (
                      <>
                        <li>
                          <a className="nav-link scrollto" href="#awords">
                            Awards & Media
                          </a>
                        </li>
                        <li>
                          <a className="nav-link scrollto" href="#partnerships">
                            Partnerships
                          </a>
                        </li>
                        <li>
                          <a className="nav-link scrollto" href="#gallery">
                            Gallery
                          </a>
                        </li>
                      </>
                    )}
                  </ul>

                  {/* hamburger */}
                  {/* <div className="hamburger-menu" onClick={this.handleNav}>
                  <GiHamburgerMenu className="resmenu" />
                </div> */}

                  {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
                </nav>
              )}
            </div>
          </div>
          {/* hamburger */}
          <div className="hamburger-menu">
            {!this.state.showNav ? (
              <GiHamburgerMenu className="resmenu" onClick={this.handleNav} />
            ) : (
              <AiOutlineClose className="resmenu" onClick={this.handleNav} />
            )}
          </div>
        </header>
      </div>
    );
  }
}
