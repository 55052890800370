import { combineReducers } from "redux";
import { types } from "./actionTypes";
let homeinitialstate = {
  products: undefined,
  productList: undefined,
  filteredProductList: undefined,
  allOffers: "",
  productDetails: "",
  productValue: null,
  newUserDataPostResponse: "",
  identityForProductComp: undefined,
  allskus: undefined,
  marketPriceData: undefined,
};

let homeReducer = (state = homeinitialstate, action) => {
  switch (action.type) {
    case types.FETCH_CATEGORIES_SUCCESS:
      // console.log(action.payload);
      return { ...state, products: action.payload };
    case types.FETCH_PRODUCTS_SUCCESS:
      // console.log(action.payload);
      return { ...state, productList: action.payload, identityForProductComp: 0 };
    case types.FETCH_FILTERED_PRODUCTS_SUCCESS:
      // console.log(action.payload);
      return { ...state, filteredProductList: action.payload, identityForProductComp: 1 };
    case types.FETCH_ALL_OFFERS_SUCCESS:
      // console.log("all offers inside reducer", action.payload);
      return { ...state, allOffers: action.payload };
    case types.FETCH_PRODUCTDETAILS_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        productValue: action.payload.prodValue,
        productDetails: action.payload.realData,
      };
    case types.POST_NEW_USER_REQUEST_SUCCESS:
      return { ...state, newUserDataPostResponse: action.payload };
    case types.FETCH_ALL_SKUS_SUCCESS:
      return { ...state, allskus: action.payload };
    case types.MARKET_PRICE_SUCCESS:
      return { ...state, marketPriceData: action.payload }
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  hr: homeReducer,
});

export default rootReducer;
