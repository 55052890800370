import React from "react";
import { Redirect } from "react-router-dom";
import Home from '../pages/Home/Home'
import Productdetails from "../pages/Products/Productdetails";
import Products from "../pages/Products/products"
import PrivacyPolicy from "../pages/Privacy/PrivacyPolicy";

const appRoutePages = [

    { path: "/home", exact: true, component: Home },
    { path: "/productdetails/:prodid", exact: true, component: Productdetails },
    { path: "/products", exact: true, component: Products },
    { path: "/privacypolicy", exact: true, component: PrivacyPolicy },

    ////always it should at bottom
    { path: "/", exact: true, component: () => <Redirect to="/home" /> },

];
export { appRoutePages }