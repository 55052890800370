import React, { Component } from 'react'
import fishVariety from "../../assets/img/fish variety balck 1.svg";
import ratedExpert from "../../assets/img/rated expert black 1.svg";
import freshBlack from "../../assets/img/fresh black 1.svg";
import safePackaging from "../../assets/img/safe packaging black 1.svg";
import doorStep from "../../assets/img/door step delivery black 1.svg";
import discountBlack from "../../assets/img/discount black 1.svg";
export default class Features extends Component {
  render() {
    return (
        <section id="features" className="about section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-4 d-flex align-items-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="">
                <h3>Is your fish FreshR?</h3>
                <div className="lines mt-2 m-0"></div>
                <div className="italic">
                  <i>
                    Are you a wholesaler/trader/retailer of fish & meat
                    products?
                  </i>
                </div>
                <p>
                  If yes, FreshR is your one-stop destination for all your
                  needs!
                </p>
              </div>
            </div>
            <div
              className="col-lg-8 featured-services pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="row">
                <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box">
                    <div className="icon">
                      <img src={fishVariety} className="img-fluid" alt="" />
                    </div>

                    <p className="description">
                      Explore a wide variety of products - rohu, catla,
                      mrigal, shrimp, crabs, marine fish, eggs, poultry
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box">
                    <div className="icon">
                      <img src={ratedExpert} className="img-fluid" alt="" />
                    </div>

                    <p className="description">
                      Access suppliers and farmers across India and view
                      their ratings
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box">
                    <div className="icon">
                      <img src={freshBlack} className="img-fluid" alt="" />
                    </div>

                    <p className="description">
                      Absolutely fresh - watch your fish getting caught &
                      packed at the pond
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={safePackaging}
                        className="img-fluid"
                        alt=""
                      />
                    </div>

                    <p className="description">
                      Best packaging & maximum ice, so that you can make the
                      most profits!
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box">
                    <div className="icon">
                      <img src={doorStep} className="img-fluid" alt="" />
                    </div>

                    <p className="description">
                      Doorstep delivery of your order, early in the morning
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={discountBlack}
                        className="img-fluid"
                        alt=""
                      />
                    </div>

                    <p className="description">
                      Best prices, cash backs & discounts!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
