import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

export default class PrivacyPolicy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <section className="privacyPolicy">
        <Container>
          <Row className="innerPrivacyPolicy">
            <h3 className="text-center mb-3">Privacy Policy</h3>
            {/* <div class="lines mb-4"></div> */}
            <p>
              FreshR, a brand operating under Zoofresh Foods Private Limited,
              has designed this Privacy Policy to explain how we handle
              personally identifiable information collected from Customers/Users
              (you/ your) through this website -{" "}
              <a href="/">www.freshr.co.in</a> &nbsp; (“Website”),the FreshR
              mobile application ("Freshr App") (together referred to as the
              "Platform") . As FreshR services evolve, we may revise this
              policy, so please check back frequently. If you do not agree with
              any part of this Policy, please stop using our Platform
              immediately.
            </p>
            <p>
              By using the Platform (or even just browsing the Platform), you
              expressly consent to our use and disclosure of your personal
              information in accordance with this Privacy Policy.
            </p>
            <p>
              This Policy also covers the treatment of personally identifiable
              information that FreshR backend technology providers/partners may
              share with it. This document describes the usage of your personal
              data acquired by FreshR.
            </p>
            <p>
              This Policy sets out how we will use and protect your information.
              We will use your personal information only for the purposes and in
              the manner allowed by the applicable data protection laws. We
              recommend that you read this Privacy Policy carefully.
            </p>
            <br />
            <h4>1. Kind of Personal Data collected by FreshR:</h4>
            <br />
            <p>
              Information shared during registration and account creation: To
              order products on our Platform you will be required to complete a
              registration process with us. As part of this process, you will be
              required to provide your name and phone number, address, pin code
              and tax details alongwith financial information such as your
              account information. Proof of identity and proof of address will
              also be required.
            </p>
            <p>
              Visitors to our Platform may choose to submit their name, email
              address and/or other information to learn more about our products,
              Offers, to take part in a Company sponsored event, or to
              participate in a survey among other things.
            </p>
            <p>
              Your information including credit/debit card number, UPI ID,
              billing address, payment instrument details, etc and other details
              as per several payment options, may be collected by our payment
              gateway service provider’s secure online payment system. Such
              information is governed by our payment gateway service provider’s
              privacy policy
            </p>
            <p>
              In addition to the above, to avoid any risk or fraud, we also
              collect information such as IP address, the browser, and language
              settings etc. from your computer, phone, tablet or other device
              that you may be using to access our Platform. We may also receive
              information about you from other sources. These include business
              partners, such as affiliate partners, and other independent third
              parties, and anything we get from them may be combined with
              information provided by you. We collect data about how you
              interact with our Platform. This includes data such as interaction
              patterns (such as screen actions, gestures: taps, scrolls) access
              dates and times, App features or pages viewed, App crashes and
              other system activity, type of browser, and third-party sites or
              services used before or in the course of interacting with our
              Platform
            </p>
            <br />
            <h4>2. Reason for collecting and Processing the Data :</h4>
            <br />
            <br />
            <p>
              We collect the personal information to render our services
              efficiently. We process and collect your information mainly for
              the following purposes:
            </p>
            <ul>
              <li>
                To help you purchase/ acquire the Products/Services that you
                have requested{" "}
              </li>
              <li>
                To contact you if required in relation to the Product/Services
                that you have purchased/requested to any communications that you
                might have sent us.
              </li>
              <li>To provide you with customer care</li>
              <li>For security and fraud prevention purposes</li>
              <li>To help improve our Applications and our Services</li>
              <li>
                The use of this information is necessary for the purpose of our
                legitimate interests.
              </li>
            </ul>
            <h4>3. Sharing of Data</h4>
            <br />
            <br />
            <p>
              We currently share your personal data, where necessary, with the
              following recipients:
            </p>
            <ul>
              <li>
                you and, where you request, any third party you ask us to share
                it with.
              </li>
              <li>Acquiring Banks, Facility providers.</li>
              <li>
                governmental, legal, regulatory, or similar authorities, on
                request or where required, including for the purposes of
                reporting any actual or suspected breach of applicable law or
                regulation;
              </li>
              <li>
                third party service providers used for the delivery of some
                aspects of the Services (e.g. SMS delivery, email delivery, web
                notifications, online payment facility, app notifications and
                phone number lookup);
              </li>
            </ul>
            <p>
              Our Applications may, interface with third party services that you
              have previously interacted with (e.g. Facebook), or contain links
              to third party content. Please note that FreshR is not responsible
              for such services or content, or the privacy policies associated
              with those services or content. We recommend that you review any
              third party’s privacy policy before accessing such services or
              content.
            </p>
            <h4>4. Security of Your Personal Data</h4>
            <p>
              FreshR limits access to personal information to authorized
              employees, associates, partners and officers. Additionally, our
              third-party service providers are held to stringent standards of
              privacy and confidentiality. We maintain physical, electronic and
              procedural safeguards to protect the information against loss,
              misuse, damage or modification and unauthorized access or
              disclosure. Some of the other central features of our information
              security program
            </p>
            <ul>
              <li>Use of specialised technology such as firewalls</li>
              <li>
                Testing of the security and operability of products and services
                by the IT department before that are introduces to the internet
              </li>
              <li>
                mplementing controls to identity, authenticate and authorise
                access to various systems and sites
              </li>
              <li>
                Continually updating our security practices in light of new
                risks and developments in technology
              </li>
            </ul>
            <p>
              We will retain your personal data for as long as we deem it
              necessary to enable you to use our services, to provide our
              services to you (including maintaining the online user account (if
              created)), to comply with applicable laws, resolve disputes with
              any parties and otherwise as necessary to allow us to conduct our
              business, including to detect and prevent fraud or other illegal
              activities. All personal data we retain will be subject to this
              Privacy Policy.
            </p>
            <h4>5. Control of Data</h4>
            <p>
              You may request us for an overview of their data by putting up an
              email to us. In certain situations, you can ask us to modify,
              erase, or block or restrict the processing of, the personal data
              we hold about you, or object to particular ways in which we are
              using your personal data; and
            </p>
            <p>
              We rely on you to ensure that your personal data is complete,
              accurate and current. Please do inform us promptly of any changes
              to or inaccuracies of your personal data by contacting us.
            </p>
            <br />
            <h4>6. Personal Data of Children</h4>
            <p>
              ur Platform is not meant to be used by children, and we do not
              knowingly solicit or collect personal information from persons
              under the age of 18 (eighteen).
            </p>
            <br />
            <h4>7. What if FreshR is bought by another company?</h4>
            <p>
              We reserve the right to transfer information (including your
              personal data) to a third party in the event of a sale, merger,
              liquidation, receivership or transfer of all or substantially all
              of the assets of our company. The third party must only use your
              personal data for the purposes that you provided it to us in the
              first place and as set out in this privacy notice. You will be
              notified in the event of any such transfer.
            </p>
            <br />
            <h4>8. Cookies</h4>
            <p>
              What is a Cookie? A cookie is a small text file of letters and
              numbers that is stored by the browser on your computer when you
              visit certain websites. A cookie allows a website to distinguish
              your computer from other users’ computers. Cookies do not contain
              Personal Data unless you have already given this information to
              the Website. You may delete or block the cookies, if that is what
              you choose to do. Note that this may make it less convenient for
              you to use our websites.
            </p>
            <p>
              If you have any questions or concerns regarding privacy using the
              Services, please send us a detailed message at below details:{" "}
            </p>
            <p className="m-0">
              <b>Mr. Sanchit Jain</b>
            </p>
            <p className="m-0">
              <b>Zoofresh Foods Private limited</b>
            </p>
            <p className="m-0">
              <b>
                Reg Office: KHATA NO 111, JAGANNATHPUR, MEDINIPUR, Kalahandi,
                Odisha, 766001
              </b>
            </p>
            <p className="m-0">
              <b>
                Email:
                <a href="mailto:sanchit@freshr.co.in"> sanchit@freshr.co.in</a>
              </b>
            </p>
            <br />
            <br />
            <h3 className="text-center mb-3">Terms & Conditions</h3>
            <p>
              FreshR, a brand operating under Zoofresh Foods Private Limited
              (the Company), has designed these Terms of Service (“Agreement”)
              to explain use of or access to the “Platform”, which includes the
              website–<a href="/">www.freshr.co.in</a> &nbsp; ("Website"), the
              FreshR mobile application on iOS or Android devices including
              phones, tablets or any other electronic device (" App"). For the
              purposes of this Agreement, wherever the context so requires "you"
              or "User" shall mean any person including any natural or legal
              person who uses the Platform or buys any product of FreshR, using
              any computer system or other device including but not limited to
              any mobile device, handheld device and tablet.
            </p>
            <h4>ON-BOARDING:</h4>
            <br />
            <p>
              To avail the Services, a User would be required to create a
              profile/sign-up on the Platform (“Profile”) using his/her email ID
              and phone number among other details. In addition to setting up
              username and password to create the Profile, the User will be
              required to furnish certain details, including but not limited to
              phone numbers and details of its businesses.
            </p>
            <p>
              The User warrants that all information furnished in connection
              with its Profile is and shall remain accurate and true in all
              respects. The User further agrees and undertakes to promptly
              update its details on the Platform in the event of any change or
              modification of such details.{" "}
            </p>
            <p>
              The User is solely responsible for maintaining the security and
              confidentiality of its username and password and agrees to
              immediately notify the Company in writing of any disclosure or
              unauthorized use of its Profile or any other breach of security
              with respect to its Profile. The User expressly agrees to be
              liable and accountable for all activities that take place through
              its Profile in furtherance of the use of Service or otherwise.{" "}
            </p>
            <p>
              he Company expressly excludes any liability for any unauthorized
              access to a User’s Profile. The User agrees to receive
              communications from the Company regarding: (i) information
              relating to transactions recorded on the Platform; (ii) requests
              for payment; (iii) information about the Company and the Services;
              (iv) promotional offers and services from the Company and its
              third party partners, and (v) any other matter in relation to the
              Services.
            </p>
            <h4>THIRD PARTY SERVICES:</h4>
            <br />
            <p>
              The Services may include services, content, documents, and
              information owned by, licensed to, or otherwise made available by
              a third party (“Third Party Services”) or contain links to Third
              Party Services. Users understand that Third Party Services are the
              responsibility of the third party that created or provided it and
              acknowledge that use of such Third-Party Services is solely at
              their own risk.
            </p>
            <p>
              {" "}
              The Company makes no representations and hereby expressly excludes
              all warranties and liabilities arising out of or pertaining to
              such Third-Party Services, including their accuracy or
              completeness. Further, all intellectual property rights in and to
              Third Party Services are the property of the respective third
              parties.{" "}
            </p>
            <p>
              The Company enables payments via payment service providers (“PSP”)
              partners and the User should take care not to share his personal
              pin or OTP with any third party intentionally or unintentionally.
              The Company never solicits information such as pin or OTP over a
              call or otherwise. The Company shall not be liable for any fraud
              due to the sharing of such details by the User. The providers
              providing Third Party Services / PSP partners shall not be liable
              for any fraud due to sharing of such details by the User. If any
              of such fraudulent transactions occur and where the User shares
              his debit/credit card or payment information, the Company may
              share relevant information of such transaction if the victim
              approaches the Company via proper channels including customer
              support number given below
            </p>
            <h4>DELIVERY, HANDLING CHARGES AND TAXES:</h4>
            <br />
            <p>
              Company may charge such delivery and handling charges, as maybe
              determined by Company from time to time along with the applicable
              taxes. Although Company strives to deliver the orders on time,
              there may be situations wherein the actual time taken for delivery
              of a Product shall vary from the delivery time mentioned at the
              time of ordering such Product. Company shall keep you updated
              about any delays in delivering of Products.
            </p>
            <h4>INTELLECTUAL PROPERTY:</h4>
            <br />
            <p>
              All rights, title, and interest in and to the Platform and
              Services, including all intellectual property rights arising out
              of the Platform and Services, are owned by or otherwise lawfully
              licensed by the Company. Subject to compliance with these Terms,
              the Company grants the User a non-exclusive, non-transferable,
              non-sub licensable, royalty-free, revocable, and limited licence
              to use the Platform and Services in accordance with these Terms
              and its written instructions issued from time to time.
            </p>
            <p>
              The User should assume that everything the User sees or reads on
              the Platform is protected under the Indian Copyright Act, 1957 and
              other intellectual property laws of India and may not be used
              except with the prior written permission of the Company. The
              Company may freely use, copy, disclose, publish, display,
              distribute without any payment of royalty, acknowledgement, prior
              consent, or any other form of restriction arising out of the
              User’s intellectual property rights.
            </p>
            <p>
              Except as stated in these Terms, nothing in these Terms should be
              construed as conferring any right in or license to the Company’s
              or any third party’s intellectual rights.{" "}
            </p>
            <p>
              The contents of this Platform, including but not limited to the
              text and images herein and their arrangements, unless otherwise
              noted, are copyright-protected in the whole and every part of this
              Platform and the same belongs to the Company and may not be used,
              sold, licensed, copied or reproduced in whole or in part in any
              manner or form or in or on any media to any person without the
              prior written consent of the Company.
            </p>
            <h4>INDEMNITY:</h4>
            <br />
            <p>
              You shall indemnify, defend at the Company’s option, and hold the
              Company, its parent companies, subsidiaries, affiliates, and their
              officers, associates successors, assigns, licensors, employees,
              directors, agents, and representatives, harmless from and against
              any claim, demand, lawsuits, judicial proceeding, losses,
              liabilities, damages and costs (including, without limitation,
              from all damages, liabilities, settlements, costs and attorneys’
              fees) due to or arising out of your access of the Platform.
            </p>
            <h4>GOVERNING LAW:</h4>
            <br />
            <p>
              All disputes arising out of or in connection with this Agreement
              shall be governed in accordance with the applicable laws of the
              Republic of India. Any and all claims, disputes or controversies
              arising from or related to this Agreement, whether existing at or
              arising after the effective date of the Agreement, shall be
              submitted to binding arbitration under the Arbitration and
              Conciliation Act, 1996. The seat and venue of the arbitration
              shall be New Delhi, India.
            </p>
            <h4>REMEDIES:</h4>
            <br />
            <p>
              Any violation or threatened violation of this Agreement will cause
              irreparable injury to Company, entitling Company to obtain
              injunctive relief in addition to all legal remedies without
              showing or proving any actual damage and without any bond being
              required to be posted.
            </p>
            <br />
            <br />
            <h3 className="text-center mb-3"> Refunds and Cancellation</h3>
            <h4>Cancellation Policy:</h4>
            <br />
            <p>
              Please note that orders once placed on the FreshR platform can
              only be canceled before 24 hours of delivery. You may get in touch
              with our customer service agent through the FreshR mobile app or
              as per details mentioned on our Website www.freshr.co.in
            </p>
            <h4>Refund Policy:</h4>
            <br />
            <p>
              Incase of any spoilage/damaged products, please notify us of the
              rejects strictly within 30 mins of receipt of the goods and
              confirming the same by entering OTP on our mobile app.
            </p>
            <p>
              Products should be returned in their original packaging along with
              the original labels and invoices, to the same delivery partner.
            </p>
            <h4>Refunds:</h4>
            <br />
            <p>
              We will process the refund after receipt of the product by FreshR.
              Refund will be processed based on the mode of payment of the order
            </p>
            <p>
              Orders paid by credit/ debit card will be refunded by credit back
              to the credit/ debit card within 7 to 10 working days and the
              refund will reflect in the next statement.{" "}
            </p>
            <p>
              Orders paid by UPI and net banking accounts will be credited back
              to bank account.
            </p>
          </Row>
        </Container>
      </section>
    );
  }
}
