import React, { Component } from 'react'
import Select from 'react-select';

export default class SelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClearable: true,
            isDisabled: false,
            isLoading: false,
            isRtl: false,
            isSearchable: true,
        }

    }
    render() {
        let { isClearable,
            isDisabled,
            isLoading,
            isRtl,
            isSearchable } = this.state
        // console.log(this.props);

        let selectedValue;
        // console.log(this.props.prevPageValue);
        if (this.props?.prevPageValue != undefined) {
            selectedValue = { label: this.props?.prevPageValue?.catName, value: this.props?.prevPageValue?._id }
        } else {
            selectedValue = false;
        }
        return (
            <Select
                // onChange={this.props.func}
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name="color"
                // id="categoryselector"
                options={this.props.options}
                defaultValue={selectedValue}
                onChange={(e) => { this.props?.getValue(e); this.props?.func()}}
                value={this.props.value}
            />
        )
    }
}
