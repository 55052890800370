import React, { Component } from "react";
import Slider from "react-slick";
import { Container, CardBody, CardText, Card, Row } from "reactstrap";
import { connect } from "react-redux";
import { getMarketPrice } from "./store/actions";

class Carouselcomponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // items: [
      //   {
      //     caption: 'ROHU', captionDesc: '‘(1.8 kg*Digha*)', captionValue: '₹ 200'
      //   },
      //   {
      //     caption: 'Catla', captionDesc: '‘(1.8 kg*Digha*)', captionValue: '₹ 500'
      //   },
      //   {
      //     caption: 'Chilika', captionDesc: '‘(1.8 kg*Digha*)', captionValue: '₹ 100'
      //   },
      //   {
      //     caption: 'Crab', captionDesc: '‘(1.8 kg*Digha*)', captionValue: '₹ 200'
      //   },
      //   {
      //     caption: 'Chilika', captionDesc: '‘(1.8 kg*Digha*)', captionValue: '₹ 100'
      //   },
      // ]
    };
  }

  componentDidMount = () => {
    this.props?.getMarketPrice();
  };
  componentDidUpdate = () => {
    if (
      this.props.marketPriceData &&
      this.state.items != this.props.marketPriceData
    )
      this.setState({ items: this.props.marketPriceData });
    // console.log(this.props.marketPriceData);
  };

  // skuString(s) {
  //   let pattern = /[a-zA-Z\s]/igm;
  //   return s?.match(pattern);
  // }

  // skuKGString(s) {
  //   let pattern = /\(.*?\)/gm;
  //   return s?.match(pattern);
  // }
  render() {
    let settings = {};
    let deviceWidth = window?.innerWidth;
    switch (deviceWidth < 480 ? "mobile" : deviceWidth < 1080 ? "tablet" : "pc") {
      case "mobile":
        settings = {
          dots: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          pauseOnFocus:true,
          pauseOnHover:true
          // slidesToShow: 1,
          // slidesToScroll: 1,
          // initialSlide: 0,
        };
        break;
      case "tablet":
        settings = {
          dots: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          pauseOnFocus:true,
          pauseOnHover:true,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        };
        break;
      case "pc":
        settings = {
          dots: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          pauseOnFocus:true,
          pauseOnHover:true,
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        };
        break;
    }


    

    return (
      <></>
      // <Container fluid className="CarouseDivTwoContainer">
      //   <Row className="sliderDiv">
      //     <Slider {...settings}>
  
      //       {this.state.items?.map((item, index) => {
      //         return (
      //           <Card key={index} className="d-flex align-items-stretch">
      //             {item != null && item != undefined ? (
      //               <CardBody className="card-body-ticker mycard">
      //                 <div className="skudetails">
      //                   <h6 className="mainskuheading">
      //                     {this.skuString(item?.skuname)}
      //                   </h6>
      //                   <span className="skuspan">
      //                     {this.skuKGString(item?.skuname)}
      //                   </span>
      //                 </div>
      //                 <CardText>{/* {item?.captionDesc} */}</CardText>

      //                 <p className=" align-items-center aboveValue">
      //                   <div className="skuinnerdetails">
      //                     <p
      //                       style={{ marginRight: "15px" }}
      //                       className="innerpara"
      //                     >
      //                       {" "}
      //                       &#8377; {item?.newprice}
      //                     </p>
      //                     <span
      //                       className={
      //                         item.ticker != "Negative"
      //                           ? "CarouseDivTwo"
      //                           : "CarouseDivOne"
      //                       }
      //                     >
      //                       &#8377;{" "}
                            
      //                       {item?.oldprice
      //                         ? Number((item?.newprice - item?.oldprice).toFixed(2))
      //                         : item?.newprice}
      //                       <span className="arrow-sku">
      //                         {item.ticker != "Negative" ? (
      //                           <svg
      //                             width="12"
      //                             height="12"
      //                             viewBox="0 0 38 19"
      //                             fill="none"
      //                             className="CarouseDivTwoSvg"
      //                             xmlns="http://www.w3.org/2000/svg"
      //                           >
      //                             <path d="M0 0L18.9099 18.912L37.824 0H0Z" />
      //                           </svg>
      //                         ) : (
      //                           <svg
      //                             width="12"
      //                             height="12"
      //                             viewBox="0 0 38 19"
      //                             className="CarouseDivOneSvg"
      //                             xmlns="http://www.w3.org/2000/svg"
      //                           >
      //                             <path d="M0 0L18.9099 18.912L37.824 0H0Z" />
      //                           </svg>
      //                         )}
      //                       </span>
      //                     </span>
      //                   </div>
      //                 </p>
      //               </CardBody>
      //             ) : (
      //               ""
      //             )}
      //           </Card>
      //         );
      //       })}
      //       {/* </Col> */}
      //     </Slider>
      //   </Row>
      // </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    marketPriceData: state?.hr?.marketPriceData,
  };
};

export default connect(mapStateToProps, { getMarketPrice })(Carouselcomponent);
