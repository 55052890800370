export const types = {
  FETCH_CATEGORIES_SUCCESS: "FETCH_CATEGORIES_SUCCESS",
  FETCH_CATEGORIES: "FETCH_CATEGORIES",
  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_FILTERED_PRODUCTS: "FETCH_FILTERED_PRODUCTS",
  FETCH_FILTERED_PRODUCTS_SUCCESS: "FETCH_FILTERED_PRODUCTS_SUCCESS",
  FETCH_ALL_OFFERS: "FETCH_ALL_OFFERS",
  FETCH_ALL_OFFERS_SUCCESS: "FETCH_ALL_OFFERS_SUCCESS",
  FETCH_PRODUCTDETAILS: "FETCH_PRODUCTDETAILS",
  FETCH_PRODUCTDETAILS_SUCCESS: "FETCH_PRODUCTDETAILS_SUCCESS",
  POST_NEW_USER_REQUEST: "POST_NEW_USER_REQUEST",
  POST_NEW_USER_REQUEST_SUCCESS: "POST_NEW_USER_REQUEST_SUCCESS",
  FETCH_ALL_SKUS: "FETCH_ALL_SKUS",
  FETCH_ALL_SKUS_SUCCESS: "FETCH_ALL_SKUS_SUCCESS",
  CATEGORY_BY_SKU:"CATEGORY_BY_SKU",
  // CATEGORYLIST
  MARKET_PRICE:"MARKET_PRICE",
  MARKET_PRICE_SUCCESS:"MARKET_PRICE_SUCCESS"
};
