import React, { Component } from 'react'
import partner1  from "../../assets/img/1_1zeg3D-rnU4eN6gYIwM16w@2x 1.svg"
import partner2  from "../../assets/img/rsz_1crowd_logo 1.svg"
import partner3  from "../../assets/img/1200px-Bharat_Petroleum_Logo 1.svg"
import partner4  from "../../assets/img/villgro-1920x876 1.svg"
import partner5  from "../../assets/img/KIIT-TBI-Logo- 1.svg"
import logos  from "../../assets/img/logos.jpeg"
import partner6 from "../../assets/img/Upaya-Logo-Transparent-FINAL 1.svg"
import partner7  from "../../assets/img/1558926611632 1.svg"
import partner8  from "../../assets/img/5da76315a79e22716b417758_startup-odisha 1.svg"
import partner9  from "../../assets/img/NuaOdisha-112870-opelfed-logo 1.svg"
import partner10  from "../../assets/img/download (2) 1.svg"
export default class PartnerShip extends Component {
  render() {
    return (
        <section id="partnerships" className="clients section-bg">
        <div className="container" data-aos="zoom-in">
          <h3 className="pt-4">Partnerships</h3>
          <div className="lines mt-2"></div>
          <div className="row mt-5">
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner1}
              
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner2}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner3}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner4}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner5}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={logos}
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner6}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner7}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner8}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner9}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <img
                src={partner10}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
