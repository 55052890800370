import React, { Component } from 'react'
import order1  from "../../assets/img/order1.png"
import order2  from "../../assets/img/order2.png"
import order3  from "../../assets/img/order3.png"
export default class Order extends Component {
  render() {
    return (
        <section id="order-now" className="order-now">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h3>Order Now !</h3>
                <div className="lines mt-2"></div>
              </div>

              <div className="row">
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={order1}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <p>
                      Download our app, browse products and place your order!
                      Watch it getting caught, packed & delivered!
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={order2}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <p>Visit our nearest FreshR wholesale point</p>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={order3}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <p>
                      write to us at
                      <br />
                      <a href="orders@freshr.co.in">orders@freshr.co.in</a>
                      <br />
                      and we'll call you back
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
    )
  }
}
