import React, { Component } from "react";
import "./brain.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { appRoutePages } from "./routes/";
import AppRoute from "./routes/route";
import VerticalLayout from "./components/verticalLayout/";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import LoadingSpinner from "../src/common/LoadingSpinner";
import { Container, Row, Col } from "reactstrap";
import "../src/assets/css/style.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  getLayout = () => {
    let layoutCls = VerticalLayout;

    return layoutCls;
  };
  componentDidMount() {
    this.isTimer();
  }
  isTimer = () => {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 300);
  };
  componentWillUnmount() {
    clearTimeout(this.isTimer);
  }
  render() {
    const Layout = this.getLayout();
    return (
      // <div className="App">
      //    <Header/>
      //    <SubHeader/>
      //    {/* <Home/> */}
      //    {/* <Products/> */}
      //    <Productdetails/>
      //    <Footer/>
      // </div>
      <>

        {this.state.isLoading ? (
          <Container fluid>
          <Col
            style={{ display: "flex" }}
            xs={12}
            sm={12}
            md={12}
            className="rightCol"
          >
            <Row className="modal-dialog">
              <LoadingSpinner />
            </Row>
          </Col>
          </Container>
        ) : (
          <React.Fragment>
            <Provider store={store}>
              <Router>
                <Switch>
                  {appRoutePages.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                    />
                  ))}
                </Switch>
              </Router>
            </Provider>
          </React.Fragment>
        )}
      </>
    );
  }
}

export default App;
