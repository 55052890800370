import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
} from "reactstrap";
import FishImage from "../../images/fresh-butterfish1.png";
import OnePic from "../../images/onePic.png";
import TwoPic from "../../images/twoPic.png";
import ThreePic from "../../images/threePic.png";
import FourPic from "../../images/fourPic.png";
import OneHoverPic from "../../images/oneHoverPic.png";
import TwoHoverPic from "../../images/twoHoverPic.png";
import ThreeHoverPic from "../../images/threeHoverPic.png";
import FourHoverPic from "../../images/fourHoverPic.png";
import {
  fetchProducts,
  fetchfilteredproducts,
  fetchCategories,
  getSkusData,
  getCategoryBySku,
} from "../../store/actions";
import { connect } from "react-redux";
import AccordianComponent from "../../common/AccordianComponent";
import SelectComponent from "../../common/SelectComponent";
import LoadingSpinner from "../../common/LoadingSpinner";
import moment from "moment";
import HomeLogin from "../Login/HomeLogin";
import { WideVariety } from "../../common/errorAndResponse";
import imagepending from "../../images/image-pending.jpg";
class products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callBackData: undefined,
      productList: [],
      loadingSpinner: true,
      selectCatData: undefined,
      selectSizeData: undefined,
      selectSkuData: undefined,
      sortBy: undefined,
      sortByPrice: undefined,
      sortByName: undefined,
      sortByRating: undefined,
      typeDataShow: undefined,
      PaginationLimitValue: 12,
      visibility: true,
      AccordionCollapse: false,
      sortBySelected: "",
      products: [
        {
          name: "ROHU",
          price: "450/Kg",
          ordQuantity: "10",
          deliveryDate: "27-10-2021",
          rating: "10",
        },
        {
          name: "ROHU",
          price: "450/Kg",
          ordQuantity: "10",
          deliveryDate: "27-10-2021",
          rating: "10",
        },
        {
          name: "ROHU",
          price: "450/Kg",
          ordQuantity: "10",
          deliveryDate: "27-10-2021",
          rating: "10",
        },
        {
          name: "ROHU",
          price: "450/Kg",
          ordQuantity: "10",
          deliveryDate: "27-10-2021",
          rating: "10",
        },
        {
          name: "ROHU",
          price: "450/Kg",
          ordQuantity: "10",
          deliveryDate: "27-10-2021",
          rating: "10",
        },
        {
          name: "ROHU",
          price: "450/Kg",
          ordQuantity: "10",
          deliveryDate: "27-10-2021",
          rating: "10",
        },
      ],
      categories: [
        // { label: "Fish", value: "fish" },
        // { label: "Prawn", value: "prawn" },
        // { label: "Exotic", value: "exotic" },
        // { label: "Fresh Water", value: "Fresh Water" },
        // { label: "Salt Water", value: "Salt Water" }
      ],
      Seller: [
        { label: "Hilsa White", value: "Hilsa White" },
        { label: "Rohu", value: "Rohu" },
        { label: "Catla", value: "Catla" },
      ],
      skuData: [],
      size: [
        { label: "1 kg", value: 1 },
        { label: "2 kg", value: 2 },
        { label: "3 kg ", value: 3 },
        { label: "4 kg ", value: 4 },
        { label: "5 kg ", value: 5 },
        { label: "6 kg ", value: 6 },
        { label: "7 kg ", value: 7 },
        { label: "8 kg ", value: 8 },
        { label: "9 kg ", value: 9 },
        { label: "10 kg ", value: 10 },
      ],
      sortingSelectData: [
        { label: "Price: Low To High", value: "rate_ASC" },
        { label: "Price: High To Low", value: "rate_DESC" },
        { label: "Seller Rating: Low To High", value: "supplierrating_ASC" },
        { label: "Seller Rating: High To Low", value: "supplierrating_DESC" },
      ],
    };
  }

  callbackFunc = (data) => {
    if (data) this.setState({ callBackData: data });
  };

  makeACall = (
    cat,
    sortBy,
    offset,
    limit,
    min_size,
    max_size,
    skudata,
    minimum_price,
    maximum_price
  ) => {
    window.scrollTo(0, 0);
    this.props.fetchProducts(
      {
        cat: cat,
        sortBy: sortBy,
        offset: offset,
        limit: limit,
        // size: size,
        min_size: min_size,
        max_size: max_size,
        skudata: skudata,
        min: minimum_price,
        max: maximum_price,
      },
      this.callbackFunc
    );
    this.setState({ callBackData: undefined });
    this.setState({ loadingSpinner: true });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    // console.log(this.props.location?.data);

    this.props.getSkusData();

    let {
      categories,
      skudata,
      min_size,
      max_size,
      minimum_price,
      maximum_price,
    } = this.getData();

    if (this.props.location?.data) {
      document.getElementsByClassName("card-header")[0].click();
      this.setState({ productList: [] });
    }

    this.makeACall(
      this.props.location?.data?._id,
      this.state.sortBy,
      0, //offset
      this.state.PaginationLimitValue,
      // size,
      min_size,
      max_size,
      skudata,
      minimum_price,
      maximum_price
    );

    this.props.fetchCategories();
  };
  componentDidUpdate = () => {
    setTimeout(() => {
      if (Array.isArray(this.state.callBackData?.docs)) {
        this.state.callBackData?.docs.map((item, iter) => {
          var a = document.getElementsByClassName("ratingStartDiv")[iter];
          if (a != undefined) {
            a.setAttribute(
              "class",
              `${a.className} ratingStarDiv${item.supplierrating}`
            );
          }
        });
      }
    }, 0);

    let normalcatarry = [];
    if (this.props.categories != undefined) {
      if (this.state.categories.length == 0) {
        this.props.categories?.docs?.map((item) => {
          normalcatarry.push({
            label: item?.categoryName,
            value: item?._id,
            categoryImage: item?.categoryImage[0],
          });
        });
        if (normalcatarry.length != 0) {
          this.setState({ categories: [...normalcatarry] });
        }
      }
    }
  };

  productHandler = (data) => {
    this.props.history.push({ pathname: `/productdetails/${data.productID}` });
  };

  getData = () => {
    let categories = this.state.selectCatData;
    let skudata = this.state.selectSkuData;
    let sortByPrice = this.state.sortByPrice;
    let sortByName = this.state.sortByName;
    let sortByRating = this.state.sortByRating;

    let minimum_price = document.getElementById("exampleInput")?.value;
    let maximum_price = document.getElementById("exampleInput1")?.value;
    let min_size = document.getElementById("minSizeInp")?.value;
    let max_size = document.getElementById("maxSizeInp")?.value;
    return {
      categories,
      skudata,
      min_size,
      max_size,
      minimum_price,
      maximum_price,
    };
  };

  checkTheValues = (a) => {
    setTimeout(() => {
      let {
        categories,
        skudata,
        min_size,
        max_size,
        minimum_price,
        maximum_price,
      } = this.getData();

      if (a !== "fetch") {
        // we are not fetching just enabling or disabling the button
        if (categories != undefined) {
          if (this.state.visibility == true) {
            this.setState({ visibility: false });
          }
        } else {
          if (this.state.visibility == false) {
            this.setState({ visibility: true });
          }
        }
      } else {
        this.makeACall(
          categories,
          this.state.sortBy,
          0, //offset
          this.state.PaginationLimitValue,
          // size,
          min_size,
          max_size,
          skudata,
          minimum_price,
          maximum_price
        );
      }
    }, 0);
  };

  render() {
    let { categories, Seller, MOQ } = this.state;
    let productList = [];

    const mappingListData = (data) => {
      data?.forEach((item) => {
        const pattern = /\(.*?\)/gm;
        productList.push({
          categoryName: item?.categoryName,
          name: item?.sku?.skuName ?? "",
          rangeKg: item?.sku?.skuName?.match(pattern) ?? "",
          rate: item?.rate + "/KG",
          supplierRating: item?.supplierrating,
          deliveryDate: item.deliveryDate,
          size: item?.size,
          sellerRating: item?.rating,
          moq: item?.moq,
          id: item?._id,
          productID: item?._id,
          img:
            item.images.length <= 0
              ? item?.sku?.skuImage[0]
                ? item?.sku?.skuImage[0]
                : imagepending
              : item?.images[0],
          orderQty: item?.availableQuantity,
        });
      });
      if (this.state.loadingSpinner && this.state.callBackData != undefined) {
        //for loading spinner
        this.setState({ loadingSpinner: false });
      }
    };

    if (this.state.callBackData != undefined) {
      mappingListData(this.state.callBackData.docs);
    }

    const getClickedOffsetData = (e, iden) => {
      if (this.state.typeDataShow == undefined) {
        this.setState({ typeDataShow: "pagiWalaData" });
      }
      let offsetval;
      let num;
      if (iden != undefined) {
        let arrChildNodes = document.getElementById("pagiUl").childNodes;
        arrChildNodes.forEach((data) => {
          if (data?.className == "active") {
            num = data.innerHTML;
          }
        });

        if (iden === "front") {
          offsetval = parseInt(num) * this.state.PaginationLimitValue;
        } else {
          offsetval = (parseInt(num) - 2) * this.state.PaginationLimitValue;
        }
      } else {
        offsetval =
          (parseInt(e.target.innerHTML) - 1) * this.state.PaginationLimitValue;
      }

      let {
        categories,
        skudata,
        min_size,
        max_size,
        minimum_price,
        maximum_price,
      } = this.getData();

      this.makeACall(
        categories,
        this.state.sortBy,
        offsetval,
        this.state.PaginationLimitValue,
        // size,
        min_size,
        max_size,
        skudata,
        minimum_price,
        maximum_price
      );
    };

    const forArrows = (totalLenOfRecords, offset) => {
      setTimeout(() => {
        if (totalLenOfRecords < 13) {
          document
            .getElementById("paginationSvg1")
            ?.setAttribute("class", "hide");
          document
            .getElementById("paginationSvg2")
            ?.setAttribute("class", "hide");
        } else {
          if (offset == 0) {
            //forBack
            document
              .getElementById("paginationSvg1")
              ?.setAttribute("class", "noOpacity svgOneDiv");
            document
              .getElementById("paginationSvg2")
              ?.setAttribute("class", "opacity");
          } else if (
            offset + this.state.PaginationLimitValue >=
            totalLenOfRecords
          ) {
            // FrontArrows

            document
              .getElementById("paginationSvg1")
              ?.setAttribute("class", "opacity svgOneDiv");
            document
              .getElementById("paginationSvg2")
              ?.setAttribute("class", "noOpacity");
          } else {
            document
              .getElementById("paginationSvg1")
              ?.setAttribute("class", "opacity svgOneDiv");
            document
              .getElementById("paginationSvg2")
              ?.setAttribute("class", "opacity");
          }
        }
      }, 200);
    };

    const displayNumberofClicksInTable = (data, offset, totalLenOfRecords) => {
      let els = [];

      if (totalLenOfRecords > this.state.PaginationLimitValue + 1) {
        for (var a = 0; a < data; a++) {
          if (a == offset / this.state.PaginationLimitValue) {
            els.push(
              <li
                onClick={(e) => getClickedOffsetData(e)}
                style={{ cursor: "pointer" }}
                className="active"
              >
                {a + 1}
              </li>
            );
          } else {
            els.push(
              <li
                onClick={(e) => getClickedOffsetData(e)}
                style={{ cursor: "pointer" }}
              >
                {a + 1}
              </li>
            );
          }
        }
      }

      return els;
    };

    const paginationNumbersAndArrows = () => {
      forArrows(
        this.state.callBackData?.length,
        this.state.callBackData?.offset
      );
      return displayNumberofClicksInTable(
        this.state.callBackData?.length / this.state.PaginationLimitValue,
        this.state.callBackData?.offset,
        this.state.callBackData?.length
      );
    };

    const checkEndValue = (a, b) => {
      if (a >= b) {
        return b;
      } else {
        return a;
      }
    };

    const checkPagiTextToMention = () => {
      if (this.state.callBackData?.length > 1) {
        return `Showing ${this.state.callBackData?.offset + 1} to
      ${checkEndValue(
        this.state.callBackData?.offset + this.state.PaginationLimitValue,
        this.state.callBackData?.length
      )}
     of ${this.state.callBackData?.length} entries `;
      } else {
        return "";
      }
    };
    return (
      <>
        <div className="catHeaderProductDiv">
          <Container>
            <Row className="">
              <Col xs={12} sm={9} md={9}>
                <Row>
                  <Col xs={12} sm={8} md={9} lg={10}>
                    {categories.length > 0 && categories && (
                      <Row>
                        {categories.map((ele) => (
                          <Col
                            xs={6}
                            // sm={4}
                            md={4}
                            lg={3}
                            className={
                              this.state.selectCatData === ele.value
                                ? "activeProductCatDiv pr-3"
                                : ""
                            }
                            onClick={() => {
                              this.setState(
                                { selectCatData: ele.value },
                                () => {
                                  this.checkTheValues("fetch");
                                }
                              );
                            }}
                            key={ele.value}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "60%",
                                  cursor: "pointer",
                                }}
                                src={OnePic}
                                alt="FishCategoryImage"
                                className="nonActiveCatDiv"
                              />
                              <img
                                style={{
                                  width: "100%",
                                  height: "60%",
                                  cursor: "pointer",
                                }}
                                src={TwoHoverPic}
                                alt="FishCategoryImage"
                                className="activeCatDiv"
                              />
                              <p
                                style={{ cursor: "pointer" }}
                                className="CatageryName"
                              >
                                {ele.label}
                              </p>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Col>
                  <Col xs={12} sm={4} md={3} lg={2}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState(
                          {
                            selectCatData: undefined,
                            sortBy: undefined,
                            sortBySelected: "",
                          },
                          () => {
                            this.checkTheValues("fetch");
                          }
                        );
                      }}
                      outline
                      className="userFilterBtn"
                    >
                      Reset All
                    </Button>
                  </Col>
                </Row>

                {/* </div> */}
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Form>
                  <div className="d-flex align-items-center justify-content-end catlistinnerselectDiv">
                    <div>
                      <Label for="exampleSelect">Sort by :</Label>
                    </div>
                    <div>
                      <SelectComponent
                        style={{
                          width: "100%",
                        }}
                        func={() => {}}
                        getValue={(val) => {
                          if (val !== null) {
                            this.setState({
                              sortBy: val.value,
                              sortBySelected: val,
                            });
                          } else {
                            this.setState({
                              sortBy: undefined,
                              sortBySelected: "",
                            });
                          }
                          this.checkTheValues("fetch");
                        }}
                        options={this.state.sortingSelectData}
                        value={
                          this.state.sortBySelected
                            ? this.state.sortBySelected
                            : ""
                        }
                      />
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        <br />
        <div className="mainContent pbottom40 productsContent">
          <Container>
            <Row>
              {this.state.loadingSpinner ? (
                <Col
                  style={{ display: "flex" }}
                  xs={12}
                  sm={12}
                  md={12}
                  className="rightCol "
                >
                  <Row style={{ margin: "auto" }}>
                    <LoadingSpinner />
                  </Row>
                </Col>
              ) : productList.length != 0 ? (
                <Col xs={12} sm={12} md={12} className="rightCol mg-tb ">
                  <Row>
                    {productList.map((item, id) => {
                      return (
                        <Col
                          key={id}
                          xxl={3}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={6}
                          className="productPaddingDiv"
                        >
                          <div className="rightColInnerDiv">
                            <div className="productImageDiv">
                              <img src={item.img} alt="FishProductImage" />
                            </div>
                            <div className="boxStyle">
                              <div className="paddingSide"
                              style={{
                                height:"220px"
                              }}>
                                <Row>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <h3>{item?.name || ""}</h3>
                                    <p className="productListRed">
                                      {/* {item?.rangeKg || ""} */}
                                    </p>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <h5>Rs {item.rate}</h5>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <p>
                                      MOQ:{" "}
                                      <span className="fw-400">
                                        {item.moq || "N.A"}
                                      </span>
                                    </p>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <p className="">
                                      Delivery date: <br />
                                      <span className="fw-400">
                                        {item.deliveryDate
                                          ? moment(item.deliveryDate).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "N.A"}
                                      </span>
                                    </p>
                                  </Col>
                                  {/* <Col xs={6} sm={6} md={6} className="">
                                    <p>Seller Rating:</p>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <p>
                                      <div className=" ratingStartDiv">
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>{" "}
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <span className="fw-400 fs-12">
                                          {" "}
                                          ({item.supplierRating})
                                        </span>
                                      </div>
                                    </p>
                                  </Col> */}
                                </Row>
                              </div>
                              <div>
                                <Button
                                  outline
                                  className="buttonDivStyle"
                                  onClick={() => this.productHandler(item)}
                                >
                                  More
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center justify-content-between productsPaginationDiv">
                        <p className="mb-0">{checkPagiTextToMention()}</p>
                        <div className="d-flex align-items-center">
                          <svg
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              getClickedOffsetData(undefined, "back")
                            }
                            width="12"
                            height="17"
                            viewBox="0 0 12 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            id="paginationSvg1"
                          >
                            <path
                              d="M2.08691 15.335L9.00513 8.41699L2.08691 1.49902"
                              stroke="#212529"
                              stroke-width="3"
                              stroke-linecap="round"
                            />
                          </svg>
                          <ul
                            id="pagiUl"
                            className="d-flex align-items-center mb-0"
                          >
                            {paginationNumbersAndArrows()}
                          </ul>

                          <svg
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              getClickedOffsetData(undefined, "front")
                            }
                            width="12"
                            height="17"
                            viewBox="0 0 12 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            id="paginationSvg2"
                          >
                            <path
                              d="M2.08691 15.335L9.00513 8.41699L2.08691 1.49902"
                              stroke="#212529"
                              stroke-width="3"
                              stroke-linecap="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <h3 className="m-auto w-50 text-center wideVariety">
                  {WideVariety}
                </h3>
              )}
            </Row>
          </Container>
        </div>
        <div></div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.hr.products,
    productListData: state.hr.productList,
    filteredProductListData: state.hr.filteredProductList,
    dataIdentity: state.hr.identityForProductComp,
    allskusData: state.hr.allskus,
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchfilteredproducts,
  fetchCategories,
  getSkusData,
  getCategoryBySku,
})(products);
