import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Footerlogo from "../../images/freshrfooterlogo.png";
import facebook from "../../assets/img/Path 12004.png";
import linkin from "../../assets/img/Path 12005.png";
import linkin1 from "../../assets/img/Path 12006.png";
import youtube from "../../assets/img/Path 12008.png";
import { Link } from "react-router-dom";
import HomeLogin from "../../pages/Login/HomeLogin";
import logo from "../../assets/img/Group 1951.png";
export default class Footer extends Component {
  render() {
    return (
      <div>
        <section className="formBackimg">
          <Row className="homeLoginDivCss">
            <Col
              xxl={6}
              xl={6}
              lg={6}
              sm={6}
              xs={12}
              md={12}
              className="d-flex justify-content-center align-items-center text-center homeLogLeft responsiveFix"
            >
              <div className="">
                <img src={logo} />
                <div className="">
                  <h4 className="text-white pt-3">Is your fish FreshR?</h4>
                </div>
                <div className="white-border"></div>
                <div className="italicText">
                  <i className="text-white">
                    Are you a wholesaler/trader/retailer<br></br> of fish & meat
                    products?
                  </i>
                </div>
                <div className="py-3">
                  <p>
                    If yes, FreshR is your one-stop destination<br></br> for all
                    your needs!{" "}
                  </p>
                </div>
              </div>
            </Col>

            <Col
              xxl={12}
              xl={6}
              lg={6}
              xs={12}
              sm={6}
              md={12}
              className="homeLogRight"
            >
              <h3 className="redColor">Get the FreshR App!</h3>
              <h2 className="blackColor">Want to try the FreshR experience?</h2>
              <div class="lines mt-4"></div>
              <p className="fs-21">Get on-boarded on our mobile app now!</p>

              <HomeLogin />
            </Col>
          </Row>
        </section>

        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="footer-links">
                    <nav
                      id="navbar"
                      className="navbar d-flex justify-content-center"
                    >
                      <ul>
                        <li>
                          <a
                            className={
                              `nav-link scrollto text-white ` +
                              (window.location.pathname.includes("home")
                                ? `active`
                                : ``)
                            }
                            href="home"
                          >
                            Home
                          </a>
                        </li>

                        {window.location.pathname === "/home" && (
                          <>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#features"
                              >
                                Features
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#freshr-product"
                              >
                                Products
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#order-now"
                              >
                                Order Now
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#seller"
                              >
                                Seller
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#transporter"
                              >
                                Transporter
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#awords"
                              >
                                Awards & Media
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#partnerships"
                              >
                                Partnerships
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link scrollto text-white"
                                href="#gallery"
                              >
                                Gallery
                              </a>
                            </li>
                          </>
                        )}
                      </ul>
                    </nav>
                  </div>
                  <div className="py-4">
                    <h4>Contact Us</h4>
                    <div className="lines mt-2 "></div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="p-1 px-2">(+91) 7653896955</div>
                    <div className="p-1 px-2">hello@freshr.co.in</div>
                  </div>
                  <div className="py-3">
                    Zoo Fresh Foods Pvt. Ltd.(FreshR),
                    <br />
                    Hauz khas, New Delhi - 110016,
                    <br />
                  </div>
                  <div className="social-links">
                    <span className="pe-3">Follow Us on</span>
                    <a
                      href="https://www.facebook.com/FreshRZooFreshFoods/"
                      className="facebook"
                    >
                      <img src={facebook} className="facebookWidth" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/freshrzoofreshfoods"
                      className="linkedin"
                    >
                      <img src={linkin1} />
                      &nbsp;
                      <img src={linkin} />
                    </a>
                    &nbsp;
                    {/* <a href="/home" className="facebook">
                      <img src={youtube} />
                    </a> */}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="container py-2">
            <div className="d-flex justify-content-between">
              <div className="copyright">© FreshR. All Rights Reserved.</div>
              <div className="">
                <Link to="/privacypolicy">
                  Privacy Policy and Terms & Conditions
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
