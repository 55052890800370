import { types } from "./actionTypes";

export let homeaction = (data = "none") => {
  return {
    type: "gettingHomeData",
    payload: data,
  };
};

export const fetchCategories = () => ({
  type: types.FETCH_CATEGORIES,
});

export const fetchProducts = (data, callback) => ({
  type: types.FETCH_PRODUCTS,
  data,
  callback
});

export const fetchfilteredproducts = (obj) => ({
  type: types.FETCH_FILTERED_PRODUCTS,
  payload: obj,
});

export const fetchAllOffers = () => ({
  type: types.FETCH_ALL_OFFERS,
});

export const fetchProductDetails = (id) => ({
  type: types.FETCH_PRODUCTDETAILS,
  payload: id,
});

export const postUserRequest = (data, callback) => ({
  type: types.POST_NEW_USER_REQUEST,
  data,
  callback,
});
export const getSkusData = () => ({
  type: types.FETCH_ALL_SKUS
})
export const getCategoryBySku = (payload, callback) => ({
  type: types.CATEGORY_BY_SKU,
  payload,
  callback,
});
export const getMarketPrice = () => ({
  type: types.MARKET_PRICE
})