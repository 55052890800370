import React, { Component } from "react";
import transactioHistory from "../../assets/img/transaction history 1.svg";
import buyer1 from "../../assets/img/buyer 1.svg";
import creditCard from "../../assets/img/credit card 1.svg";
import cirtifyied from "../../assets/img/cirtifyied 1.svg";
import group1 from "../../assets/img/Group 1904 1.png";
export default class Seller extends Component {
  render() {
    return (
      <section id="seller" className="seller-platform">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-md-7">
              <div className="">
                <h3>Become a seller on FreshR platform</h3>
                <div className="lines mt-2 m-0"></div>
                <div className="italic">
                  <i>Are you a farmer / trader of fish & meat products? </i>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 px-2"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="seller-info">
                    <img
                      src={transactioHistory}
                      className="img-fluid"
                      alt=""
                      data-aos="fade-up"
                    />
                    <p>
                      Sell on FreshR platform, build formal transaction history
                      with banks / NBFCs
                    </p>
                  </div>
                </div>
                <div
                  className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 px-2"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="seller-info">
                    <img
                      src={buyer1}
                      className="img-fluid"
                      alt=""
                      data-aos="fade-up"
                    />
                    <p>
                      Access genuine buyers across India, for all variety &
                      grades of produce{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 px-2"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="seller-info">
                    <img
                      src={creditCard}
                      className="img-fluid"
                      alt=""
                      data-aos="fade-up"
                    />
                    <p>Immediate payments, credit facilities</p>
                  </div>
                </div>
                <div
                  className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 px-2"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="seller-info">
                    <img
                      src={cirtifyied}
                      className="img-fluid"
                      alt=""
                      data-aos="fade-up"
                    />
                    <p>
                      Upgrade your operations using FreshR's tech stack, become
                      a certified seller
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 fixing-img">
              <img src={group1} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
