import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <Layout>
          <ReactNotification />
          <Component {...props} {...rest} />
        </Layout>
      );
    }}
  />
);

const mapStateToProps = (state) => {};
export default AppRoute;
