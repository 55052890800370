import React, { Component } from "react";
import img1  from "../../assets/img/awords/img1.png"
import img2  from "../../assets/img/awords/img2.png"
import img3  from "../../assets/img/awords/img3.png"
import img4  from "../../assets/img/awords/img4.png"
import img5  from "../../assets/img/awords/img5.png"
import img6  from "../../assets/img/awords/img6.png"
import img7  from "../../assets/img/awords/img7.png"
import img8  from "../../assets/img/awords/img8.png"
import img9  from "../../assets/img/awords/img9.png"
import img10  from "../../assets/img/awords/img10.png"
import img11 from "../../assets/img/awords/img11.png"
import img12  from "../../assets/img/awords/img12.png"
import img13 from "../../assets/img/awords/img13.png"
import img14  from "../../assets/img/awords/img14.png"
import img15  from "../../assets/img/awords/img15.png"
import img16  from "../../assets/img/awords/img16.png"

class Gallery extends Component {
    render(){
        return (
            <section id="gallery">
            <div className="container gallery-container">
              <h3>Gallery</h3>
              <div className="lines mt-2 m-0 mb-4"></div>

              <div className="slider">
                <div className="slide-track">
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/watch?v=SXmJdkMS4HQ"
                    >
                      <img src={img1}
                   alt="" />
                      <span className="caption">
                        FreshR Fulfilment Centre - Bhawanipatna
                      </span>
                    </a>
                  </div>
                  <div className="slide">
                    <img src={img2}
                     alt="" />
                    <span className="caption">FreshR Produce</span>
                  </div>
                  <div className="slide">
                    <img src={img3}
                 alt="" />
                    <span className="caption">Team of FreshR</span>
                  </div>
                  <div className="slide">
                    <img src={img4}
                     alt="" />
                    <span className="caption">
                      Inside of FreshR Centre - Bhawanipatna
                    </span>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://vimeo.com/416481728/44ea849b77"
                    >
                      <img src={img5}
                      alt="" />
                      <span className="caption"> FreshR Site Visit </span>
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/730292180679632"
                    >
                      <img src={img6}
                      alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/733280723714111"
                    >
                      <img src={img7}
                     alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/733280677047449

              "
                    >
                      {" "}
                      <img src={img8}
                     alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/733293853712798"
                    >
                      {" "}
                      <img src={img9}
                    alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/733293850379465"
                    >
                      {" "}
                      <img src={img10} alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/907834589592056"
                    >
                      {" "}
                      <img src={img11} alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/907834589592056"
                    >
                      {" "}
                      <img src={img12}
                     alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/907834142925434

              "
                    >
                      {" "}
                      <img src={img13}
                       alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/907834286258753"
                    >
                      {" "}
                      <img src={img14} alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/907833906258791"
                    >
                      {" "}
                      <img src={img15} alt="" />
                    </a>
                  </div>
                  <div className="slide">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/FreshRZooFreshFoods/photos/1321411101567734"
                    >
                      {" "}
                      <img src={img16}
                      alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
    }
}
export default Gallery