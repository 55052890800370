import React, { Component } from 'react'
import ink  from "../../assets/img/awords/ink42 1.png"
import urstory  from "../../assets/img/awords/urstory 1.png"
import upaya  from "../../assets/img/awords/upaya 1.png"
import mycity  from "../../assets/img/awords/mycitylink 1.png"
export default class Media extends Component {
  render() {
    return (
        <section id="awords" className="pb-0 my-2">
        <div className="container">
          <div className="slider">
            <div className="">
              <h3 className="text-white text-center pt-3">Media</h3>
              <div className="lines"></div>
            </div>

            <div className="row">
              <div className="col p-2">
                <a
                  target="_blank"
                  href="https://inc42.com/resources/how-zomatoisation-will-impact-indian-food-supply-chain/"
                >
                  <img src={ink}
                  alt="" />
                </a>
              </div>
              <div className="col p-2">
                <a
                  target="_blank"
                  href="https://yourstory.com/2018/09/social-impact-women-entrepreneurs/amp"
                >
                  <img src={urstory} alt="" />
                </a>
              </div>
              <div className="col p-2">
                <a
                  target="_blank"
                  href="https://www.upayasv.org/blog/zoofresh-spotlight"
                >
                  <img src={upaya} alt="" />
                </a>
              </div>
              <div className="col p-2">
                <a
                  target="_blank"
                  href="https://mycitylinks.in/this-couple-is-taking-fresh-hygeienic-meat-to-consumers/"
                >
                  <img src={mycity} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
