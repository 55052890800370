import React, { Component } from "react";
import LoginForm from "./LoginForm";

export default class HomeLogin extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <LoginForm />
      </React.Fragment>
    );
  }
}
