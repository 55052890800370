import React, { Component } from "react";
import Slider from "react-slick";
import { CardImg, Container, Card, Row } from "reactstrap";

export default class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          src: this.props.images[0],
          altText: 'Slide One'
        },
       
      ]
    }
  }
  componentDidMount(){
    if(this.props.images?.length!==1 && this.props.images?.length>0){
      this.setState({items:this.props.images.map((item)=>{
          return {src:item,altText:"carouselImage"}
      })})
    }
  }
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0
    };
    return (
      <Container fluid>
        <Row className="productSliderPopupDiv">
          <Slider {...settings}>
            {this.state.items.map((item, index) => {
              return <Card key={index} >
                <div>
                  <CardImg variant="top" src={item.src} />
                </div>
              </Card>
            })}
          </Slider>
        </Row>
      </Container>
    );
  }
}