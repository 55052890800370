import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { postUserRequest } from "../../store/actions";
import { store } from "react-notifications-component";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      number: "",
    };
  }

  valueStore = (e, type) => {
    if (type === "name") this.setState({ name: e.target.value });
    if (type === "number") this.setState({ number: e.target.value });
    // console.log(this.state);
  };

  submit = () => {
    let data = {
      phone: this.state.number,
      name: this.state.name,
      userAccountSource: "web",
    };
    this.props.postUserRequest(data, (res) => {
      // console.log(res);
      if (res.statusCode && res.statusCode === 200) {
        this.setState({ name: "", number: "" });
        store.addNotification({
          title: "Request Sent",
          message: "Your Request has been received, we will get back to you.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      } else
        store.addNotification({
          title: "Error",
          message: (Array.isArray(res?.message)) ? res?.message[0] : res?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
    });
  };

  render() {
    return (
      <React.Fragment>
        <p>
          Please leave your details here so that we can get in touch and enable
          your log-in!
        </p>
        <div>
          <Form>
            <FormGroup>
              <Input
                name="name"
                placeholder="Enter your name"
                type="text"
                value={this.state.name}
                onChange={(e) => {
                  this.valueStore(e, "name");
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="exampleNumber"
                name="number"
                placeholder="Mobile number"
                type="number"
                value={this.state.number}
                onChange={(e) => {
                  this.valueStore(e, "number");
                }}
              />
            </FormGroup>

            <Button
              onClick={() => {
                this.submit();
              }}
              outline
              className="buttonDivStyle"
            >
              Submit
            </Button>
            {/* <p className="signinpara">Already registered? Sign in here</p> */}
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    newUserDataPostResponse: state.hr.response,
  };
};

export default connect(mapStateToProps, { postUserRequest })(LoginForm);
