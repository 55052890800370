import React, {Component} from 'react'
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";

import Header from "./Header";
// import SubHeader from "./SubHeader";
import Footer from "./footer";

class Layout extends Component {
    constructor(props) {
      super(props);
    }



    render() {
        return (
          <React.Fragment>
            <div id="preloader">
              <div id="status">
                <div className="spinner">
                  <i className="ri-loader-line spin-icon"></i>
                </div>
              </div>
            </div>
    
            <div id="layout-wrapper">
              <Header/>
              {/* <SubHeader/> */}
              <div className="main-content">
                {this.props.children}
                <Footer />
              </div>
            </div>
          </React.Fragment>
        );
      }
    }

export default Layout