import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchCategoriesList,
  fetchProducts,
  fetchfilteredproducts,
} from "../../store/actions";

import ProducList from "./ProductList.js";
import Gallery from "./Gallery.js";
import PartnerShip from "./PartnerShip.js";
import Media from "./Media.js";
import Transporter from "./Transporter.js";
import Seller from "./Seller.js";
import Order from "./Order.js";
import Features from "./Features.js";
import Group from "../../assets/img/Group 2093.png";
import { Link, NavLink } from "react-router-dom";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loginModal: true,
      // imagedata: [],
    };
  }
  // toggleLoginModal = () => {
  //   console.log("testing");
  //   this.setState({
  //     loginModal: !this.state.loginModal,
  //   });
  // };

  // componentDidMount = () => {
  //   this.props.fetchCategories();
  // };
  componentDidMount() {}

  render() {
    const orderNow = {
      backgroundColor: "#02C84",
      border: "none",
      fontSize: 21,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 48,
      paddingLeft: 48,
      fontWeight: 500,
    };

    return (
      // <div>

      //   <div className="mainContent">
      //     <LoginModal
      //       isOpen={this.state.loginModal}
      //       toggle={this.toggleLoginModal}
      //     />
      //     <Container fluid>
      //       <Row className="justify-content-md-center">
      //         <Col xs={12} md={12}>
      //           <div>
      //             <h3 className="carouselTowHeader">
      //               Market prices as on <span>18-June-2021</span>
      //             </h3>
      //             <Row>
      //               <Col>
      //                 {/* <MarketPriceCarousel/> */}
      //                 <Carouselsecond />
      //               </Col>
      //             </Row>
      //           </div>
      //         </Col>
      //       </Row>
      //       <br/>
      //       <br/>
      //       <Row>
      //         <Col className="pdtb-40">
      //           <div>
      //             <h2 className="text-center">How FreshR works</h2>
      //             <p className="text-center">Follow these simple 3 step process to place an order with us!</p>
      //             <br/>
      //             <Row>
      //               <Col xs={12} sm={4} md={4}>
      //                 <div className="aboutInnerDiv">
      //                   <div className="aboutInnerImageDiv">
      //                     <img src={StepOne} alt="imageOne"/>
      //                   </div>
      //                   <p>Go to our ‘products’ page and browse available products</p>
      //                 </div>
      //               </Col>
      //               <Col xs={12} sm={4} md={4}>
      //                 <div className="aboutInnerDiv">
      //                   <div className="aboutInnerImageDiv">
      //                     <img src={StepTwo} alt="imageOne"/>
      //                   </div>
      //                   <p>Click on ‘call’ button to speak to our agent</p>
      //                 </div>
      //               </Col>
      //               <Col xs={12} sm={4} md={4}>
      //                 <div className="aboutInnerDiv">
      //                   <div className="aboutInnerImageDiv">
      //                     <img src={StepThree} alt="imageOne"/>
      //                   </div>
      //                   <p>Place order over call!</p>
      //                 </div>
      //               </Col>
      //             </Row>
      //           </div>
      //         </Col>
      //       </Row>
      //       <Row className="justify-content-md-center pdtb-40">
      //         <Col xs={12} md={12}>
      //           <div>
      //             <h2 className="text-center">New Offers!</h2>
      //             <br />
      //             <Row>
      //               <Col>
      //                 {/* <OffersCarousel /> */}
      //                 <Carouselcomponent />
      //               </Col>
      //             </Row>
      //           </div>
      //         </Col>
      //       </Row>
      //       <Row className="justify-content-md-center pdtb-40">
      //         <Col xs={12} md={10}>
      //           <div>
      //             <h2 className="text-center">Shop By Categories</h2>

      //             <Row className="mb-3">
      //               <CategoryCarousel history={this.props.history} item={this.props?.tableData} />
      //             </Row>
      //             <br />
      //           </div>
      //         </Col>
      //       </Row>
      //     </Container>
      //   </div>
      //   <div>
      //     <Container fluid>

      //       <Row className="homeLoginDivCss">
      //         <Col
      //           xs={12}
      //           sm={6}
      //           md={5}
      //           className="d-flex align-items-center text-center homeLogLeft"
      //         >
      //           <div>
      //             <h1>Get the FreshR App!</h1>
      //             <br />
      //             <h4>
      //               Want to try the FreshR experience? Get on boarded on our
      //               mobile app now!
      //             </h4>
      //           </div>
      //         </Col>
      //         <Col xs={12} sm={6} md={7} className="homeLogRight">
      //           <HomeLogin />
      //         </Col>
      //       </Row>
      //     </Container>
      //   </div>
      // </div>
      <div>
        <main id="main">
          <section id="home" className="">
            <div className="" data-aos="zoom-out" data-aos-delay="100">
              <div className="row d-flex align-items-center   ">
                <div className="col-md-6 alignLeft col-lg-6 d-flex align-items-center middle-sec">
                  <div className="">
                    <img src={Group} className="img-fluid sliderlogo" alt="" />
                    <br />
                    <br />
                    <h3>
                      One-stop shop for all fish & meat businesses in India
                    </h3>
                    <Link to="/products">
                      <button style={orderNow}>Order Now</button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 alignRight d-flex align-items-center bgImage">
                  <div className=""></div>
                </div>
              </div>
            </div>
          </section>
          <section id="freshr-product" className="freshr-product section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h3>FreshR Products</h3>
                <div className="lines mt-2"></div>
              </div>
              <ProducList {...this.props} />
            </div>
          </section>
          <Features/>
        
          <Order />
          <Seller />
          <Transporter />
          <Media />
          <PartnerShip />
          <Gallery />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    categoriesList: state.hr.products,
    productListData: state.hr.productList,
    filteredProductListData: state.hr.filteredProductList,
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchfilteredproducts,
})(Home);
