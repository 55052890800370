import React, { Component } from 'react'
import group2  from "../../assets/img/Group 1905 1.png"
import earning  from "../../assets/img/earning 1.png"
import hasleFree  from "../../assets/img/hassle free 1.png"
import loan  from "../../assets/img/loan 1.png"
import insurance  from "../../assets/img/insurance 1.png"
export default class Transporter extends Component {
  render() {
    return (
        <section id="transporter" className="pricing">
        <div className="" data-aos="fade-up">
          <div className="section-title">
            <h3>Become our transport partner</h3>
            <div className="lines mt-2"></div>
            <div className="italic">
              <p>
                <i>
                  Do you have vehicles & drivers that you would like to
                  utilize better?
                </i>
              </p>
            </div>
          </div>

          <div
            className="row d-flex align-items-center"
            style={{ backgroundColor: "#f9f9f9" }}
          >
            <div
              className="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src={group2}
                className="img-fluid"
                alt=""
              />
            </div>

            <div
              className="col-lg-6 col-md-6 mt-4 mt-md-0 div-six"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="row d-flex align-items-center mb-4">
                <div className="col-md-2 col-sm-2">
                  <img
                    src={earning}
                  
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-10 col-sm-10 w-50">
                  <p className="m-0">
                    Earn more from your vehicles! Utilize idle vehicle &
                    staff capacity
                  </p>
                </div>
              </div>
              <div className="row d-flex align-items-center mb-4">
                <div className="col-md-2 col-sm-2">
                  <img
                    src={hasleFree}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-10 col-sm-10 w-50">
                  <p className="m-0">No product-related hassles </p>
                </div>
              </div>
              <div className="row d-flex align-items-center mb-4">
                <div className="col-md-2 col-sm-2">
                  <img
                    src={loan}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-10 col-sm-10 w-50">
                  <p className="m-0">
                    Access to vehicle loans to expand your fleet
                  </p>
                </div>
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-md-2 col-sm-2">
                  <img
                    src={insurance}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-md-10 col-sm-10 w-50">
                  <p className="m-0">Transport insurance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
