import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import OnePic from "../../images/onePic.png";
import TwoHoverPic from "../../images/twoHoverPic.png";
import {
  fetchProducts,
  fetchfilteredproducts,
  fetchCategories,
  getSkusData,
  getCategoryBySku,
} from "../../store/actions";
import { connect } from "react-redux";
import LoadingSpinner from "../../common/LoadingSpinner";
import moment from "moment";
import { Link, NavLink } from "react-router-dom";
import { WideVariety } from "../../common/errorAndResponse";
import imagepending from "../../images/image-pending.jpg";
class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callBackData: undefined,
      productList: [],
      loadingSpinner: true,
      selectCatData: undefined,
      selectSizeData: undefined,
      selectSkuData: undefined,
      sortBy: undefined,
      sortByPrice: undefined,
      sortByName: undefined,
      sortByRating: undefined,
      typeDataShow: undefined,
      visibility: true,
      AccordionCollapse: false,
      PaginationLimitValue: 12,
      sortBySelected: "",
      categories: [
        // { label: "Fish", value: "fish" },
        // { label: "Prawn", value: "prawn" },
        // { label: "Exotic", value: "exotic" },
        // { label: "Fresh Water", value: "Fresh Water" },
        // { label: "Salt Water", value: "Salt Water" }
      ],
      sortingSelectData: [
        { label: "Price: Low To High", value: "rate_ASC" },
        { label: "Price: High To Low", value: "rate_DESC" },
        { label: "Seller Rating: Low To High", value: "rating_ASC" },
        { label: "Seller Rating: High To Low", value: "rating_DESC" },
      ],
    };
  }

  callbackFunc = (data) => {
    if (data) this.setState({ callBackData: data });
  };

  makeACall = (
    cat,
    sortBy,
    offset,
    limit,
    min_size,
    max_size,
    skudata,
    minimum_price,
    maximum_price
  ) => {
    this.props.fetchProducts(
      {
        cat: cat,
        sortBy: sortBy,
        offset: offset,
        limit: limit,
        // size: size,
        min_size: min_size,
        max_size: max_size,
        skudata: skudata,
        min: minimum_price,
        max: maximum_price,
      },
      this.callbackFunc
    );
    this.setState({ callBackData: undefined });
    this.setState({ loadingSpinner: true });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.props.getSkusData();

    let {
      categories,
      skudata,
      min_size,
      max_size,
      minimum_price,
      maximum_price,
    } = this.getData();

    if (this.props.location?.data) {
      document.getElementsByClassName("card-header")[0].click();
      this.setState({ productList: [] });
    }

    this.makeACall(
      this.props.location?.data?._id,
      this.state.sortBy,
      0, //offset
      this.state.PaginationLimitValue,
      // size,
      min_size,
      max_size,
      skudata,
      minimum_price,
      maximum_price
    );

    this.props.fetchCategories();
  };
  componentDidUpdate = () => {
    setTimeout(() => {
      if (Array.isArray(this.state.callBackData?.docs)) {
        this.state.callBackData?.docs.map((item, iter) => {
          var a = document.getElementsByClassName("ratingStartDiv")[iter];
          if (a != undefined) {
            a.setAttribute(
              "class",
              `${a.className} ratingStarDiv${item.supplierrating}`
            );
          }
        });
      }
    }, 0);

    let normalcatarry = [];
    if (this.props.categories != undefined) {
      if (this.state.categories.length == 0) {
        this.props.categories?.docs?.map((item) => {
          normalcatarry.push({
            label: item?.categoryName,
            value: item?._id,
            categoryImage: item?.categoryImage[0],
          });
        });
        if (normalcatarry.length != 0) {
          this.setState({ categories: [...normalcatarry] });
        }
      }
    }
  };

  productHandler = (data) => {
    this.props.history.push({ pathname: `/productdetails/${data.productID}` });
  };

  getData = () => {
    let categories = this.state.selectCatData;
    let skudata = this.state.selectSkuData;
    let sortByPrice = this.state.sortByPrice;
    let sortByName = this.state.sortByName;
    let sortByRating = this.state.sortByRating;

    // let size = this.state.selectSizeData
    let minimum_price = document.getElementById("exampleInput")?.value;
    let maximum_price = document.getElementById("exampleInput1")?.value;
    let min_size = document.getElementById("minSizeInp")?.value;
    let max_size = document.getElementById("maxSizeInp")?.value;
    return {
      categories,
      skudata,
      min_size,
      max_size,
      minimum_price,
      maximum_price,
    };
  };

  checkTheValues = (a) => {
    setTimeout(() => {
      let {
        categories,
        skudata,
        min_size,
        max_size,
        minimum_price,
        maximum_price,
      } = this.getData();

      if (a !== "fetch") {
        if (categories != undefined) {
          if (this.state.visibility == true) {
            this.setState({ visibility: false });
          }
        } else {
          if (this.state.visibility == false) {
            this.setState({ visibility: true });
          }
        }
      } else {
        this.makeACall(
          categories,
          this.state.sortBy,
          0, //offset
          this.state.PaginationLimitValue,
          // size,
          min_size,
          max_size,
          skudata,
          minimum_price,
          maximum_price
        );
      }
    }, 0);
  };

  render() {
    let { categories, Seller, MOQ } = this.state;
    let productList = [];

    const mappingListData = (data) => {
      data?.forEach((item) => {
        if (productList.length <= 7) {
          const pattern = /\(.*?\)/gm;
          productList.push({
            categoryName: item?.categoryName,
            name: item?.sku?.skuName ?? "",
            rangeKg: item?.sku?.skuName?.match(pattern) ?? "",
            rate: item?.rate + "/KG",
            supplierRating: item?.supplierrating,
            deliveryDate: item.deliveryDate,
            size: item?.size,
            sellerRating: item?.rating,
            moq: item?.moq,
            id: item?._id,
            productID: item?._id,
            img:
              item.images.length <= 0
                ? item?.sku?.skuImage[0]
                  ? item?.sku?.skuImage[0]
                  : imagepending
                : item?.images[0],
            orderQty: item?.availableQuantity,
          });
        }
      });
      if (this.state.loadingSpinner && this.state.callBackData != undefined) {
        this.setState({ loadingSpinner: false });
      }
    };

    if (this.state.callBackData != undefined) {
      mappingListData(this.state.callBackData.docs);
    }

    return (
      <>
        <div className="catHeaderProductDiv">
          <Container fluid>
            <Row>
              <Col xxl={12} xl={12}  xs={12} sm={12} md={12} lg={12} className="freshrproducts">
                <div className="categoryOrderListingDiv">
                  {categories.length > 0 && categories && (
                    <div className="d-flex align-items-center mb-0">
                      <ul className="d-flex align-items-center mb-0 prodcat">
                        {categories.map((ele) => (
                          <li
                            className={
                              this.state.selectCatData === ele.value
                                ? "activeProductCatDiv"
                                : ""
                            }
                            onClick={() => {
                              this.setState(
                                { selectCatData: ele.value },
                                () => {
                                  this.checkTheValues("fetch");
                                }
                              );
                            }}
                            key={ele.value}
                          >
                            <img
                              src={OnePic}
                              alt="FishCategoryImage"
                              className="nonActiveCatDiv resfix"
                            />
                            <img
                              src={TwoHoverPic}
                              alt="FishCategoryImage"
                              className="activeCatDiv resfix"
                            />
                            <p>{ele.label}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {/* </div> */}
              </Col>
            </Row>
          </Container>
        </div>
        <br />
        <div className="mainContent productsContent">
          <Container fluid className="containerFluid">
            <Row>
              {this.state.loadingSpinner ? (
                <Col
                  style={{ display: "flex" }}
                  xs={12}
                  sm={12}
                  md={12}
                  className="rightCol "
                >
                  <Row style={{ margin: "auto" }}>
                    <LoadingSpinner />
                  </Row>
                </Col>
              ) : productList.length != 0 ? (
                <Col xs={12} sm={12} md={12} className="rightCol mg-tb">
                  <Row>
                    {productList.map((item, id) => {
                      return (
                        <Col
                          key={id}
                          xs={12}
                          sm={6}
                          md={6}
                          lg={3}
                          // onClick={() => this.productHandler(item)}
                          className="productPaddingDiv"
                        >
                          <div className="rightColInnerDiv">
                            <div className="productImageDiv">
                              <img src={item.img} alt="FishProductImage" />
                            </div>
                            <div className="boxStyle">
                              <div className="paddingSide"
                              style={{
                                height:"220px"
                              }}>
                                <Row>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <h3>{item?.name || ""}</h3>
                                    <p className="productListRed">
                                      {/* {item?.rangeKg || ""} */}
                                    </p>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <h5>Rs {item?.rate}</h5>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <p>
                                      {/* Order <br /> */}
                                      MOQ:{" "}
                                      <span className="fw-400">
                                        {item.moq || "N.A"}
                                      </span>
                                    </p>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <p className="">
                                      Delivery date: <br />
                                      <span className="fw-400">
                                        {item.deliveryDate
                                          ? moment(item.deliveryDate).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "N.A"}
                                      </span>
                                    </p>
                                  </Col>
                                  {/* <Col xs={6} sm={6} md={6} className="">
                                    <p>Seller Rating:</p>
                                  </Col>
                                  <Col xs={6} sm={6} md={6} className="">
                                    <p>
                                      <div className=" ratingStartDiv">
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>{" "}
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <svg
                                          width="13"
                                          height="12"
                                          viewBox="0 0 19 18"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                        </svg>
                                        &nbsp;
                                        <span className="fw-400 fs-12">
                                          {" "}
                                          ({item.supplierRating})
                                        </span>
                                      </div>
                                    </p>
                                  </Col> */}
                                </Row>
                              </div>
                              <div>
                                <Button
                                  onClick={() => this.productHandler(item)}
                                  outline
                                  className="buttonDivStyle"
                                >
                                  More
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              ) : (
                <h3 className="m-auto w-50 text-center wideVariety">
                  {WideVariety}
                </h3>
              )}
            </Row>
          </Container>
          {productList.length > 0 && (
            <div className="exploreProduct">
              <Link to="/products">Explore more products</Link>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.hr.products,
    productListData: state.hr.productList,
    filteredProductListData: state.hr.filteredProductList,
    dataIdentity: state.hr.identityForProductComp,
    allskusData: state.hr.allskus,
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchfilteredproducts,
  fetchCategories,
  getSkusData,
  getCategoryBySku,
})(ProductList);
