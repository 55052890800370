import { takeEvery, all, put, call, fork } from "redux-saga/effects";
import { getApi, postApi } from "../helpers/auth_helper";
import { types } from "./actionTypes";

function* workfetchCategories() {
  try {
    // Try to call the API
    const uri = `${process.env.REACT_APP_URL}/category/categories`;
    const response = yield call(getApi, uri);
    // console.log("FETCH_CATEGORIES", response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_CATEGORIES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch categories.", error);
  }
}

function* workfetchProducts(actiondata) {
  //only this is working for products page
  try {
    // // Try to call the API
    // console.log(actiondata.data);

    var limitVal = actiondata.data.limit;
    let offsetVal = actiondata.data.offset || 0;
    let uri = `${process.env.REACT_APP_URL}/product/products/getlist?offset=${offsetVal}&limit=${limitVal}`;

    if (actiondata.data.sortBy != undefined && actiondata.data.sortBy != "") {
      // let sort = `{"createdAt":"${actiondata.data.sortBy}"}`
      // uri = uri + `&sort=${sort}`
      const key = actiondata.data.sortBy.split("_")[0];
      const value = actiondata.data.sortBy.split("_")[1];
      let sort = `{"${key}":"${value}"}`;
      uri = uri + `&sort=${sort}`;
    }

    if (actiondata.data.cat != undefined) {
      uri = uri + `&category=${actiondata.data.cat}`;
    }
    // if (actiondata.data.size != undefined) {
    //   uri = uri + `&size=${actiondata.data.size}`
    // }
    // if (actiondata.data.min_size != "" && actiondata.data.max_size != "") {
    //   let size = `{min:${actiondata.data.min_size},max:${actiondata.data.max_size}}`
    //   uri = uri + `&size=${size}`;
    // }
    if (
      actiondata.data.min_size != "" &&
      actiondata.data.min_size != undefined
    ) {
      uri = uri + `&minsize=${actiondata.data.min_size}`;
    }
    if (
      actiondata.data.max_size != "" &&
      actiondata.data.max_size != undefined
    ) {
      uri = uri + `&maxsize=${actiondata.data.max_size}`;
    }
    // if (actiondata.data.max_size != "") {
    //   uri = uri + `&max=${actiondata.data.max_size}`
    // }
    if (actiondata.data.skudata != undefined) {
      uri = uri + `&sku=${actiondata.data.skudata}`;
    }
    if (actiondata.data.min != "" && actiondata.data.min != undefined) {
      uri = uri + `&minprice=${actiondata.data.min}`;
    }
    if (actiondata.data.max != "" && actiondata.data.max != undefined) {
      uri = uri + `&maxprice=${actiondata.data.max}`;
    }

    const response = yield call(getApi, uri);
    // console.log("FETCH_PRODUCTS", response);
    actiondata.callback(response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.FETCH_PRODUCTS_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch products.", error);
  }
}

function* getFilteredProducts(actionData) {
  // console.log(actionData.payload);
  let sort = `{"createdAt":"${actionData.payload.sortBy}"}`;
  var limitVal = actionData.payload.limit;

  let uri = `${process.env.REACT_APP_URL}/product/products/getlist?offset=0&limit=${limitVal}&sort=${sort}`;
  if (actionData.payload.cat != undefined) {
    uri = uri + `&category=${actionData.payload.cat}`;
  }
  if (actionData.payload.size != undefined) {
    uri = uri + `&size=${actionData.payload.size}`;
  }
  if (actionData.payload.skudata != undefined) {
    uri = uri + `&sku=${actionData.payload.skudata}`;
  }
  if (actionData.payload.min != "") {
    uri = uri + `&minprice=${actionData.payload.min}`;
  }
  if (actionData.payload.max != "") {
    uri = uri + `&maxprice=${actionData.payload.max}`;
  }
  // console.log(uri);
  try {
    // Try to call the API
    // const uri = ;
    const response = yield call(getApi, uri);
    // console.log("FETCH_FILTERED_PRODUCTS", response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_FILTERED_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch filtered products.", error);
  }
}
function* workFetchProductDetails(actiondata) {
  try {
    // Try to call the API
    // console.log(actiondata);
    const uri = `${process.env.REACT_APP_URL}/product/${actiondata.payload}`;
    const response = yield call(getApi, uri);
    // console.log("FETCH_PRODUCTDETATILS", response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_PRODUCTDETAILS_SUCCESS,
      payload: { prodValue: actiondata.payload, realData: response },
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch Product Details.", error);
  }
}

function* workfetchOffers() {
  try {
    // Try to call the API
    const uri = `${process.env.REACT_APP_URL}/offer/offers`;
    const response = yield call(getApi, uri);
    // console.log("FETCH_ALL_OFFERS", response);
    //   Dispatch the action to the reducers
    if (response)
      yield put({
        type: types.FETCH_ALL_OFFERS_SUCCESS,
        payload: response,
      });

  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch offers.", error);
  }
}

function* workPostNewUserData({ data, callback }) {
  try {
    // console.log("new user data saga log", data);
    // Try to call the API
    const uri = `${process.env.REACT_APP_URL}/customer/registerationrequest`;
    const response = yield call(postApi, uri, data);
    //consolelog("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.POST_NEW_USER_REQUEST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could Not post new user data.", error);
  }
}

function* getAllSkusData() {
  try {
    // Try to call the API
    const uri = `${process.env.REACT_APP_URL}/sku/list`;
    const response = yield call(getApi, uri);
    // console.log("FETCH_ALL_OFFERS", response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ALL_SKUS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch skus data.", error);
  }
}
function* WorkCategoryBySkuData({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_URL}/sku/listbycategoryid`;
    let response = yield call(postApi, uri, payload);
    callback(response);
    // yield put({
    //   type: types.SKU_CATEGORY_VIEW_DATA_SUCCESS,
    //   payload: response,
    // });
  } catch (e) {
    // console.log("Failed to fetch Category by SKU Data", e);
  }
}

function* getMarketPriceData() {
  try {
    let uri = `${process.env.REACT_APP_URL}/product/products/getmarketprice`;
    let response = yield call(getApi, uri);
    if (response)
      yield put({
        type: types.MARKET_PRICE_SUCCESS,
        payload: response,
      });
  } catch (e) {
    // console.log("Failed to fetch Market Price", e);
  }
}

export function* watchFetchCategories() {
  yield takeEvery(types.FETCH_CATEGORIES, workfetchCategories);
}

export function* watchFetchProducts() {
  yield takeEvery(types.FETCH_PRODUCTS, workfetchProducts);
}

export function* watchFetchFilteredProducts() {
  yield takeEvery(types.FETCH_FILTERED_PRODUCTS, getFilteredProducts);
}
export function* watchFetchAllOffers() {
  yield takeEvery(types.FETCH_ALL_OFFERS, workfetchOffers);
}
export function* watchFetchProductDetails() {
  yield takeEvery(types.FETCH_PRODUCTDETAILS, workFetchProductDetails);
}
export function* watchPostNewUserData() {
  yield takeEvery(types.POST_NEW_USER_REQUEST, workPostNewUserData);
}
export function* watchGetAllSkus() {
  yield takeEvery(types.FETCH_ALL_SKUS, getAllSkusData);
}
export function* watchCategoryBySkuData() {
  yield takeEvery(types.CATEGORY_BY_SKU, WorkCategoryBySkuData);
}
export function* watchMarketPrice() {
  yield takeEvery(types.MARKET_PRICE, getMarketPriceData)
}

function* rootSaga() {
  yield all([fork(watchFetchCategories)]);
  yield all([fork(watchFetchProducts)]);
  yield all([fork(watchFetchFilteredProducts)]);
  yield all([fork(watchFetchAllOffers)]);
  // yield all([fork(watchFetchFilteredProducts)]);
  yield all([fork(watchFetchProductDetails)]);
  yield all([fork(watchPostNewUserData)]);
  yield all([fork(watchGetAllSkus)]);
  yield all([fork(watchCategoryBySkuData)]);
  yield all([fork(watchMarketPrice)]);
}

export default rootSaga;
